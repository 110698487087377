import { Accordion, Col, Container, Row, Stack } from 'react-bootstrap'
import { Card } from 'react-bootstrap'
import Question from './Question'

import { useContext, useState } from 'react'
import PropTypes from 'prop-types'

import DataContext from '@Contexts/DataContext'
import SubmittableContext from '@Contexts/SubmittableContext'

import NavigationButton from '@Components/presentational/navigationButton/NavigationButton'

import '@Styles/theme.css'

const MainView = props => {
    const tabKeys = props.questions.map(question => question.id)
    const [submittable, setSubmittable] = useState(false)
    const { canSubmit } = useContext(DataContext)

    const updateSubmittable = () => {
        setSubmittable(canSubmit())
    }

    return (
        <Container fluid={'lg'} className="mb-5" style={{paddingTop: '2em'}}>
            <SubmittableContext.Provider value={{updateSubmittable}}>
                <Card className={'border-green'}>
                    <Accordion defaultActiveKey={tabKeys[0]}>
                        {props.questions.map((question, index) => (
                            <Accordion.Item key={index} eventKey={tabKeys[index]}>
                                <Accordion.Header>{question.title}</Accordion.Header>
                                <Accordion.Body>
                                    <Stack>
                                        <Question question={question} />

                                        <Row>
                                            {
                                                index > 0 ?
                                                    <Col>
                                                        <NavigationButton eventKey={tabKeys[index-1]} type={'prev'}>
                                                            Précédent
                                                        </NavigationButton>
                                                    </Col>
                                                :
                                                    null
                                            }

                                            <Col>
                                                {
                                                    (index === tabKeys.length-1) ?
                                                        <NavigationButton
                                                            eventKey={tabKeys[index+1]}
                                                            type={'post'}
                                                            disabled={!submittable}
                                                            callback={props.sendDataCallback}
                                                        >
                                                            Envoyer
                                                        </NavigationButton>
                                                        // <SendButton onClick={props.sendDataCallback} disabled={!submittable}/>
                                                    :
                                                        <NavigationButton eventKey={tabKeys[index+1]} type={'next'}>
                                                            Suivant
                                                        </NavigationButton>
                                                }
                                            </Col>
                                        </Row>
                                    </Stack>
                                </Accordion.Body>
                            </Accordion.Item>
                        ))}
                    </Accordion>
                </Card>
            </SubmittableContext.Provider>
        </Container>
    )
}

MainView.propTypes = {
    questions: PropTypes.array,
    sendDataCallback: PropTypes.func
}

MainView.defaultProps = {
    questions: []
}

export default MainView