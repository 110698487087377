import { useContext, useEffect, useRef, useState } from 'react'
import { getQuestions, postInterpreter } from '@Network/Requests'
import DataContext from '@Contexts/DataContext'
import LoadingView from '@Components/presentational/loadingView/LoadingView'
import { useReactToPrint } from 'react-to-print'
import Prescription from './Prescription'
import MainView from './MainView'
import AnalyticsLogger from '../../AnalyticsLogger'

const Form = () => {
    AnalyticsLogger.logScreen('form')

    const [loading, setLoading] = useState(true)
    const [questions, setQuestions] = useState([])
    const [responseData, setResponseData] = useState({
        professional: null,
        recommendations: null
    })
    const dataContext = useContext(DataContext)

    const printableRef = useRef()
    const handlePrint = useReactToPrint({
        content: () => printableRef.current
    })

    const sendData = async () => {
        try {
            setLoading(true)
            const data = dataContext.generateDataset()
            const responseData = await postInterpreter(data)
            setResponseData(responseData)
            setLoading(false)
        
        } catch (error) {
            AnalyticsLogger.logError(error)
        }
    }

    useEffect(() => {
        (async () => {
            try {
                const questions = await getQuestions()

                setQuestions(questions)
                
                if (questions.length > 0) {
                    dataContext.initialize(questions)
                    setLoading(false)
                }
    
            } catch (error) {
                AnalyticsLogger.logError(error)
            }
        })()
        
    }, [dataContext])

    if (loading)
        return (
            <LoadingView />
        )

    else if (responseData.recommendations == null)
        return (
            <DataContext.Provider value={dataContext}>
                <MainView questions={questions} sendDataCallback={sendData} />
            </DataContext.Provider>
        )

    else
        return (
            <Prescription
                ref={printableRef}
                recommendations={responseData.recommendations}
                professional={responseData.professional}
                handlePrint={handlePrint}
            />
        )
}

export default Form