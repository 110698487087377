import { useRef, useState } from "react"
import { Button, Container, FloatingLabel, Form, Stack } from "react-bootstrap"
import AnalyticsLogger from "../../AnalyticsLogger"
import { postFeedback } from '@Network/Requests'

const Feedback = () => {
    AnalyticsLogger.logScreen('feedback')

    const [isLoading, setIsLoading] = useState(false)
    const [sendButtonDisableState, setSendButtonDisableState] = useState(true)
    const [emailFieldValidityState, setEmailFieldValidityState] = useState(false)
    const [messageFieldValidityState, setMessageFieldValidityState] = useState(false)

    const emailInputRef = useRef(null)
    const messageInputRef = useRef(null)
    const emailErrorTextRef = useRef(null)
    const messageErrorTextRef = useRef(null)
    const feedbackSentTextRef = useRef(null)
    const messageMinimumLength = 30

    const handleEmailChange = () => {
        const valid = emailInputRef.current.value.match(emailInputRef.current.pattern)
        setEmailFieldValidityState(valid)

        if (emailInputRef.current.value.length === 0)
            emailErrorTextRef.current.innerText = "Veuillez entrer une adresse email"
        
        else if (!valid)
            emailErrorTextRef.current.innerText = "Veuillez entrer une adresse email valide"
        
        else
            emailErrorTextRef.current.innerText = ""

        setSendButtonDisableState(!(valid && messageFieldValidityState))
    }
    
    const handleMessageChange = () => {
        const length = messageInputRef.current.value.length
        const valid = length >= messageMinimumLength
        setMessageFieldValidityState(valid)

        if (length === 0)
            messageErrorTextRef.current.innerText = `${messageMinimumLength} caractères minimum`
        
        else if (length < messageMinimumLength)
            messageErrorTextRef.current.innerText = `${messageMinimumLength - length} caractères restants`

        else
            messageErrorTextRef.current.innerText = ""

        setSendButtonDisableState(!(emailFieldValidityState && valid))
    }

    const handleClick = async () => {
        try {
            setIsLoading(true)

            const status = await postFeedback({
                email: emailInputRef.current.value,
                message: messageInputRef.current.value
            })

            switch (status) {
                case 200:
                    feedbackSentTextRef.current.className = "text-success h6"
                    feedbackSentTextRef.current.innerText = "Votre message a été envoyé à nos équipes !"
                    break

                default:
                    feedbackSentTextRef.current.className = "text-danger h6"
                    feedbackSentTextRef.current.innerText = "Une erreur est survenue lors de l'envoi de votre message."
                    break
            }

            setIsLoading(false)
            
            emailInputRef.current.value = ""
            emailErrorTextRef.current.innerText = ""
            messageInputRef.current.value = ""
            messageErrorTextRef.current.innerText = ""
            setSendButtonDisableState(true)
            
        } catch (error) {
            AnalyticsLogger.logError(error)
        }
    }

    return (
        <Container fluid={'lg'}>
            <Form>
                <Stack gap={3} className='mb-5'>
                    <p className={'text-justify mt-3'}>
                        <b>Bonjour</b>, merci d'essayer apaclic.fr !<br />
                        Nous nous efforçons de fournir un service de la plus haute qualité et nous sommes très attentifs à l'impact de notre travail sur nos utilisateurs.<br />
                        Nous serions ravis que vous nous envoyiez un courriel pour nous dire ce que nous pouvons faire pour améliorer apaclic.<br />
                        Pour ce faire, utilisez le formulaire ci-dessous.<br />
                        Merci de nous consacrer un peu de votre temps. Nous apprécions vraiment !
                    </p>          

                    <Form.Group>
                        <FloatingLabel label='Adresse email'>
                            <Form.Control
                                ref={emailInputRef}
                                type="email"
                                className={emailFieldValidityState ? 'is-valid' : 'is-invalid'}
                                placeholder="prenom.nom@exemple.fr"
                                pattern='^[a-z0-9._%+-]+@[a-z0-9.-]+\.[a-z]{2,4}$'
                                onChange={handleEmailChange}
                            />
                        </FloatingLabel>

                        <Form.Text ref={emailErrorTextRef} className="text-danger h6">

                        </Form.Text>
                    </Form.Group>

                    <Form.Group>
                        <FloatingLabel label='Message'>
                            <Form.Control
                                ref={messageInputRef}
                                as="textarea"
                                className={messageFieldValidityState ? 'is-valid' : 'is-invalid'}
                                placeholder='Votre message...'
                                style={{height: '250px'}}
                                onChange={handleMessageChange}
                            />
                        </FloatingLabel>

                        <Form.Text ref={messageErrorTextRef} className="text-danger h6">

                        </Form.Text>
                    </Form.Group>

                    <Button type="button" variant="green" size='lg' className='mt-3 float-end' onClick={handleClick} disabled={sendButtonDisableState}>
                        {isLoading ? 'Envoi en cours...' : 'Envoyer'}
                    </Button>

                    <Form.Text ref={feedbackSentTextRef} className="">
                        
                    </Form.Text>

                    <Form.Text className="text-muted">
                        Vos données sont privées et ne seront jamais stockées ni partagées avec un tiers.
                    </Form.Text>
                </Stack>
            </Form>
        </Container>
    )
}

export default Feedback