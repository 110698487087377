import { css } from '@emotion/react'

export const navbar = css({
    backgroundColor: '#008515',
})

export const nav = css({
    textAlign: 'right',
})

export const navlink = css({
    color: 'white',
    '&:hover': {
        color: '#FFFFFF',
    },
    '&:active': {
        color: 'white',
    }
})

export const version = css({
    marginLeft: '10px',
})

export const logo = css({
    height: '35',
})