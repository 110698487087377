import { Card, Stack } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { createRef, useContext } from "react"
import CollapseWidget from "@Components/container/collapseWidget/CollapseWidget"
import ResponseButtons from "@Components/presentational/responseButtons/ResponseButtons"
import DataContext from "@Contexts/DataContext"

const SimpleQuestion = props => {
    const collapseRef = createRef()
    const { addProblem, removeDependencies } = useContext(DataContext)

    return (
        <Card.Body className="text-center">
            <Card.Title>{props.title}</Card.Title>
            
            <Stack gap={4}>
                <p style={{textAlign: 'center'}}>{props.question}</p>
                <ResponseButtons
                    reverse={props.reverse}
                    triggerDependenciesHandler={hasProblem => {
                        collapseRef.current.setOpen(hasProblem)

                        addProblem(props.id, props.parent, { hasProblem })

                        if (!hasProblem)
                            removeDependencies(props.id)
                    }}
                />
            </Stack>

            <CollapseWidget ref={collapseRef}>
                {props.children}
            </CollapseWidget>

            <Stack gap={4}>
                {props.childs.map((question, index) => (
                    <SimpleQuestion key={index}
                        id={question.id}
                        title={question.title}
                        question={question.question}
                        reverse={question.reverse} />
                ))}
            </Stack>
        </Card.Body>
    )
}

SimpleQuestion.propTypes = {
    id: PropTypes.string,
    parent: PropTypes.string,
    title: PropTypes.string,
    question: PropTypes.string,
    childs: PropTypes.array
}

SimpleQuestion.defaultProps = {
    title: null,
    childs: []
}

export default SimpleQuestion