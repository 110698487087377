import { Col, Row, Stack } from "react-bootstrap";
import DataContext from "@Contexts/DataContext";
import SelectionButton from "@Components/presentational/selectionButton/SelectionButton";
import SimpleQuestion from "./SimpleQuestion";
import PropTypes from 'prop-types'
import { useContext, useState } from "react";

const BodyMembersQuestion = props => {
    const [selectedBodyMembers, setSelectedBodyMembers] = useState([])
    const { addProblem } = useContext(DataContext)

    const changeSelectedBodyMembers = bodyMember => {
        setSelectedBodyMembers(prevState => {
            const index = selectedBodyMembers.indexOf(bodyMember)

            if (index > -1)
                prevState.splice(index, 1)
            else
                prevState.push(bodyMember)

            return prevState
        })
    }

    return (
        <SimpleQuestion id={props.id} parent={props.parent} title={props.title} question={props.question} reverse={props.reverse}>
            <p style={{textAlign: 'center'}}>Quel(s) membre(s) en particulier ?</p>
            
            <Stack gap={2}>
                <Row>
                    <Col>
                        <SelectionButton title={"Membre Supérieur Gauche"} handleChange={() => {
                            changeSelectedBodyMembers("MSG")
                            addProblem(props.id, props.parent, { bodyMembers: selectedBodyMembers })
                        }} />
                    </Col>

                    <Col>
                        <SelectionButton title={"Membre Supérieur Droit"} handleChange={() => {
                            changeSelectedBodyMembers("MSD")
                            addProblem(props.id, props.parent, { bodyMembers: selectedBodyMembers })
                        }} />
                    </Col>
                </Row>

                <Row>
                    <Col>
                        <SelectionButton title={"Membre Inférieur Gauche"} handleChange={() => {
                            changeSelectedBodyMembers("MIG")
                            addProblem(props.id, props.parent, { bodyMembers: selectedBodyMembers })
                        }} />
                    </Col>

                    <Col>
                        <SelectionButton title={"Membre Inférieur Droit"} handleChange={() => {
                            changeSelectedBodyMembers("MID")
                            addProblem(props.id, props.parent, { bodyMembers: selectedBodyMembers })
                        }} />
                    </Col>
                </Row>
            </Stack>
        </SimpleQuestion>
    )
}

BodyMembersQuestion.propTypes = {
    id: PropTypes.string,
    parent: PropTypes.string,
    title: PropTypes.string,
    question: PropTypes.string
}

export default BodyMembersQuestion