import { Button } from 'react-bootstrap'
import PropTypes from 'prop-types'
import { useContext, useState } from 'react'
import SubmittableContext from '@Contexts/SubmittableContext'
import '@Styles/theme.css'

const SelectionButton = props => {
    const [isSelected, setIsSelected] = useState(false)
    const { updateSubmittable } = useContext(SubmittableContext)

    const handleMouseOver = event => {
        event.target.style.opacity = 0.7
    }

    const handleMouseLeave = event => {
        event.target.style.opacity = 1.0
    }

    const handleClick = event => {
        event.target.style.opacity = isSelected ? 0.7 : 1.0
        props.handleChange()
        setIsSelected(!isSelected)
        updateSubmittable()
    }

    return (
        <>
            <Button type="button"
                    variant="outline-green"
                    className="w-100 h-100"
                    active={isSelected}
                    onClick={handleClick}
                    onMouseOver={handleMouseOver}
                    onMouseLeave={handleMouseLeave}
            >
                {props.title}
            </Button>
        </>
    )
}

SelectionButton.propTypes = {
    id: PropTypes.string,
    title: PropTypes.string,
    isSelected: PropTypes.bool,
    handleChange: PropTypes.func
}

export default SelectionButton