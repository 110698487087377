import { faArrowUpRightFromSquare } from "@fortawesome/free-solid-svg-icons"
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome"
import { Button, Col, Container, Row, Stack } from "react-bootstrap"
import AnalyticsLogger from "../../AnalyticsLogger"
import Link from "../../Link"
import Route from "../../Route"
import '@Styles/theme.css'

const Home = () => {
    AnalyticsLogger.logScreen('home')
    
    return (
        <Container className="my-5">
            <Stack gap={4}>
                <img src="logo-color.svg"
                    height="100"
                    alt="Apaclic colored logo"
                />

                <Stack gap={1}>
                    <h5 className='justify-content'>Prescrivez de l’activité physique adaptée pour votre patient en quelques clics</h5>
                    <hr />
                </Stack>

                <Row xs={1} lg={2}>
                    <Col className='mt-4'>
                        <Button
                            className={'w-100'}
                            href={Route.Form}
                            variant={'green'}
                            size={'lg'}
                        >
                            Prescrire de l'APA
                        </Button>
                    </Col>
            
                    <Col className='mt-4'>
                        <Button
                            className={'w-100'}
                            href={Link.ApaFrance}
                            variant={'green'}
                            size={'lg'}
                            target='_blank'
                        >
                            Carte <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{marginLeft: '3px'}} />
                        </Button>
                    </Col>
                </Row>
            </Stack>
        </Container>
    )
}

export default Home