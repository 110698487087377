import { Spinner, Stack } from "react-bootstrap";
import '@Styles/theme.css'

const LoadingView = () => (
    <Stack gap={2} className={'mt-5'}>
        <Spinner className={'m-auto'} animation={'border'} variant={'green'} role={'status'} />
        <p className={'m-auto'}>Chargement en cours...</p>
    </Stack>
)

export default LoadingView