import { forwardRef } from 'react'
import { Button, Row, Col, Container, Form, Stack } from 'react-bootstrap'
import AnalyticsLogger from '../../AnalyticsLogger'
import '@Styles/print.css'
import '@Styles/theme.css'

const Prescription = ({ recommendations, professional, handlePrint }, ref) => {
    AnalyticsLogger.logScreen('prescription')

    const reloadForm = () => document.location.reload()

    const recommendationsToString = () => {
        if (recommendations.length === 0)
            return []

        return recommendations
            .map(recommendation => `- ${recommendation}\n`)
            .reduce((acc, recommendationString) => acc + recommendationString)
    }

    const getNowDate = () => {
        const date = new Date()
        return `${date.getFullYear()}-${date.getMonth()}-${date.getDate()}`
    }

    return (
        <Container id="PrescriptionContent" className="mb-5" style={{paddingTop: '2em'}}>
            <Row ref={ref}>
                <Col lg={{ span: 10, offset: 1 }}>
                    <Stack gap={3}>
                        <Row>
                            <Col>
                                <Form.Group controlId="doctorName">
                                    <Form.Label>Nom du médecin</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>
                            </Col>
                            
                            <Col>
                                <Form.Group controlId="patientName">
                                    <Form.Label>Nom du patient</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Row>
                            <span>Je prescris une activité physique et/ou sportive adaptée pendant <input type="text" />, à adapter en fonction de l'évolution des aptitudes du patient.</span>
                        </Row>
                        
                        <Form.Group>
                            <Form.Label>Préconisations d'activité et recommendations</Form.Label>
                            <Form.Control as="textarea"
                                        defaultValue={recommendationsToString()}
                                        style={{ height: '200px' }} />
                        </Form.Group>

                        <Form.Group controlId="typeIntervenant">
                            <Form.Label>Type d'intervenant(s) appelé(s) à dispenser l'activité physique</Form.Label>
                            <Form.Control type="text" defaultValue={professional} />
                            <Form.Text className="text-muted">
                                En référence à l'Article D.1172-2 du Code de la santé publique<sup>1</sup>. Le cas échéant, dans le cadre d'une équipe pluridisciplinaire.
                            </Form.Text>
                        </Form.Group>

                        <Form.Check 
                            type={'checkbox'}
                            id={`default-checkbox`}
                            label={"Document remis au patient"}
                        />

                        <Row>
                            <span>La dispensation de l'activité physique adaptée ne peut donner lieu à une prise en charge financière par l'assurance maladie.</span>
                        </Row>

                        <Row>
                            <Col>
                                <Form.Group>
                                    <Form.Label>Lieu</Form.Label>
                                    <Form.Control type="text" />
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group>
                                    <Form.Label>Date</Form.Label>
                                    <Form.Control type="date" defaultValue={getNowDate()} />
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group>
                                    <Form.Label>Signature</Form.Label>
                                    <Form.Control type="hidden" />
                                </Form.Group>
                            </Col>

                            <Col>
                                <Form.Group>
                                    <Form.Label>Cachet</Form.Label>
                                    <Form.Control type="hidden" />
                                </Form.Group>
                            </Col>
                        </Row>

                        <Stack gap={1}>
                            <small id="1" className="form-text text-muted"><sup>1</sup> Décret n°2016-1990 du 30 décembre 2016 relatif aux conditions de dispensation de l'activité physique adaptée prescrite par le médecin traitant à des patients atteints d'une ALD.</small>
                            <small id="2" className="form-text text-muted"><sup>2</sup> Concerne les titulaires d'un titre à finalité professionnelle, d'un certificat de qualification professionnelle ou d'un diplôme fédéral, inscrit sur arrêté interministériel qui ne peuvent intervenir dans la dispensation d'activités physiques adaptées à des patients atteints de limitations fonctionnelles modérées que dans le cadre d'une équipe pluridisciplinaire (cf. annexe 4 de l'instruction interministérielle n°DGS/EA3/DGESIP/DS/DG/SG/2017/81 du 3 mars 2017 relative à la mise en oeuvre des articles L.1172-1 et D.1171-5 du code de la santé publique et portant guide sur les conditions de dispensation de l'activité physique adaptée prescrite par le médecin traitant à des patients atteints d'une affection de longue durée)</small>
                        </Stack>

                        <Row>
                            <Col>
                                <Button className={"w-100"} variant={"outline-danger"} size={"lg"} type={"button"} onClick={reloadForm}>
                                    Réinitialiser
                                </Button>
                            </Col>

                            <Col>
                                <Button className={"w-100"} variant={"outline-green"} size={"lg"} type={"button"} onClick={handlePrint}>
                                    Imprimer
                                </Button>
                            </Col>
                        </Row>
                    </Stack>
                </Col>
            </Row>
        </Container>
    )
}

export default forwardRef(Prescription)