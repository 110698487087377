import { initializeApp, getApps } from "firebase/app"
import { initializeAppCheck, ReCaptchaV3Provider } from 'firebase/app-check'
import { getPerformance } from "firebase/performance"
import { getFunctions, connectFunctionsEmulator } from 'firebase/functions'
import { getAnalytics } from 'firebase/analytics'

const firebaseOptions = {
    apiKey: process.env.REACT_APP_FIREBASE_API_KEY,
    projectId: process.env.REACT_APP_FIREBASE_PROJECT_ID,
    appId: process.env.REACT_APP_FIREBASE_APP_ID,
}

const firebaseApp = !getApps().length ? initializeApp(firebaseOptions) : getApps()[0]

window.self.FIREBASE_APPCHECK_DEBUG_TOKEN = process.env.NODE_ENV === 'development'

initializeAppCheck(firebaseApp, {
    provider: new ReCaptchaV3Provider(process.env.REACT_APP_RECAPTCHA_PUBLIC_KEY),
    isTokenAutoRefreshEnabled: true
})

const functions = getFunctions(firebaseApp, process.env.REACT_APP_FIREBASE_FUNCTIONS_PREFERRED_LOCATION)
const performance = getPerformance(firebaseApp)
const analytics = getAnalytics(firebaseApp)

if (process.env.NODE_ENV === 'development')
    connectFunctionsEmulator(functions, process.env.REACT_APP_EMULATOR_HOST, process.env.REACT_APP_EMULATOR_PORT)

export { functions, performance, analytics }