import { logEvent } from "firebase/analytics";
import { analytics } from "./firebase";

const AnalyticsLogger = () => {

    const logScreen = (screenName) => {
        if (process.env.NODE_ENV === 'production')
            logEvent(analytics, 'screen_view', {
                name: screenName
            })
    }

    const logError = (errorData) => {
        if (process.env.NODE_ENV === 'production')
            logEvent(analytics, 'error', errorData)
    }   

    return {
        logScreen,
        logError
    }
}

export default AnalyticsLogger()