import SimpleQuestion from './SimpleQuestion'
import PropTypes from 'prop-types'
import QuestionType from '../QuestionType'
import ComorbiditiesQuestion from './ComorbiditiesQuestion'
import BodyMembersQuestion from './BodyMembersQuestion'

const Question = props => {

    switch (props.question.type) {
        case QuestionType.Simple:
            return (
                <SimpleQuestion
                    id={props.question.id}
                    question={props.question.question}
                    childs={props.question.childs}
                    reverse={props.question.reverse}
                >

                    {props.question.dependencies.map((dependency, index) => {
                        switch (dependency.type) {
                            case QuestionType.Simple:
                                return (
                                    <SimpleQuestion
                                        key={index}
                                        id={dependency.id}
                                        parent={props.question.id}
                                        title={dependency.title}
                                        question={dependency.question}
                                        reverse={dependency.reverse}
                                    />
                                )
                    
                            case QuestionType.BodyMembers:
                                return (
                                    <BodyMembersQuestion
                                        key={index}
                                        id={dependency.id}
                                        parent={props.question.id}
                                        title={dependency.title}
                                        question={dependency.question}
                                        reverse={dependency.reverse}
                                    />
                                )
                    
                            case QuestionType.Activities:
                                return (
                                    <SimpleQuestion
                                        key={index}
                                        id={dependency.id}
                                        parent={props.question.id}
                                        title={dependency.title}
                                        question={dependency.question}
                                        reverse={dependency.reverse}
                                    >

                                        {dependency.dependencies.map((subDependency, index) => (
                                            <SimpleQuestion
                                                key={index}
                                                id={subDependency.id}
                                                parent={dependency.id}
                                                question={subDependency.question}
                                                reverse={subDependency.reverse}
                                            />
                                        ))}
                                        
                                    </SimpleQuestion>
                                )
                    
                            default:
                                throw new Error("QuestionType not implemented")
                        }
                    })}
                </SimpleQuestion>
            )

        case QuestionType.Comorbidities:
            return (
                <ComorbiditiesQuestion id={props.question.type}
                                        question={props.question.question}
                                        dependencies={props.question.dependencies} />
            )

        default:
            throw new Error("QuestionType not implemented")
    }
}

Question.propTypes = {
    question: PropTypes.shape({
        type: PropTypes.string,
        title: PropTypes.string,
        question: PropTypes.string,
        dependencies: PropTypes.array,
        childs: PropTypes.array
    })
}

export default Question