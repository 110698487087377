import { httpsCallable } from 'firebase/functions'
import { functions } from '../firebase'

const getQuestionsFunction = httpsCallable(functions, 'getQuestions')
const postInterpreterFunction = httpsCallable(functions, 'postInterpreter')
const postFeedbackFunction = httpsCallable(functions, 'postFeedback')

const getQuestions = () => new Promise(async (resolve, reject) => {
    const dayInMilliseconds = 86400000
    const date = localStorage.getItem('questions-date')

    if (date && date < Date.now() + dayInMilliseconds) {
        const cache = JSON.parse(localStorage.getItem('questions'))
        resolve(cache)

    } else {
        try {
            const response = await getQuestionsFunction()
            const questions = response.data

            localStorage.setItem('questions', JSON.stringify(questions))
            localStorage.setItem('questions-date', Date.now())

            resolve(questions)
    
        } catch (error) {
            reject(error)
        }
    }
})

const postInterpreter = (data) => new Promise(async (resolve, reject) => {
    try {
        const response = await postInterpreterFunction(data)
        resolve(response.data)
    
    } catch (error) {
        reject(error)
    }
})

const postFeedback = (data) => new Promise(async (resolve, reject) => {
    try {
        const response = await postFeedbackFunction(data)
        resolve(response.data)

    } catch (error) {
        reject(error)
    }
})

export { getQuestions, postInterpreter, postFeedback }