import { forwardRef, useImperativeHandle, useRef, useState } from 'react'
import { Collapse, Stack } from 'react-bootstrap'

const CollapseWidget = (props, ref) => {
    const [isOpen, setIsOpen] = useState(false)

    const collapse = useRef()

    useImperativeHandle(ref, () => ({
        setOpen: open => {
            if (open !== undefined)
                setIsOpen(open)
            else
                setIsOpen(!isOpen)
        }
    }))

    return (
        <div className="mt-4">
            <Collapse ref={collapse} in={isOpen}>
                <Stack gap={4}>
                    {isOpen ? props.children : null}
                </Stack>
            </Collapse>
        </div>
    )
}

export default forwardRef(CollapseWidget)