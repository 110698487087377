import { Navbar as BootstrapNavbar, Container, Nav } from 'react-bootstrap'
import Link from '../../../Link'
import Route from '../../../Route'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faArrowUpRightFromSquare } from '@fortawesome/free-solid-svg-icons'
import * as styles from './styles'
import NavLink from './NavLink'

const Navbar = () => {
    return (
        <BootstrapNavbar style={styles.navbar} collapseOnSelect expand="lg" sticky="top">
            <Container>
                <BootstrapNavbar.Brand href={Route.Home}>
                    <img src="logo-bw.svg" className="d-inline-block align-top" alt="navbar logo" height="35" style={styles.logo} />
                    <small className="text-white h6 align-bottom" style={styles.version}>v{process.env.REACT_APP_VERSION}</small>
                </BootstrapNavbar.Brand>
                <BootstrapNavbar.Toggle aria-controls="responsive-navbar-nav" />
                <BootstrapNavbar.Collapse>
                    <Nav className="float-xs-end float-lg-start" style={styles.nav}>
                        <NavLink href={Route.Home}>Accueil</NavLink>
                        <NavLink href={Route.Form}>Prescrire</NavLink>
                        <NavLink href={Link.ApaFrance} target='_blank'>Cartographie <FontAwesomeIcon icon={faArrowUpRightFromSquare} style={{marginLeft: '3px'}} /></NavLink>
                        <NavLink href={Route.Refund}>Remboursement</NavLink>
                        <NavLink href={Route.About}>À propos</NavLink>
                        <NavLink href={Route.Feedback}>Contact</NavLink>
                    </Nav>
                </BootstrapNavbar.Collapse>
            </Container>
        </BootstrapNavbar>
    )
}

export default Navbar