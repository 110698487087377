import { createContext } from "react"

const makeDataStore = () => {
    // private properties
    let initialized = false
    let mandatoryDependencies = []

    // public properties
    let problems = []
    let comorbidities = []

    /** Initialization */

    const initialize = (questions) => {
        
        const parseQuestion = (question) => {
            const dependencies = question.dependencies ? question.dependencies.map(dependency => parseQuestion(dependency)) : []
            const childs = question.childs ? question.childs.map(child => parseQuestion(child)) : []

            return {
                id: question.id,
                dependencies: dependencies,
                childs: childs
            }
        }

        mandatoryDependencies = questions.map(question => parseQuestion(question))

        initialized = true
    }


    /** Problem functions */

    const findProblemIndex = (id) => problems.findIndex(problem => problem.id === id)
    
    const addProblem = (id, parent, data) => {
        if (!initialized)
            throw new Error("You need to initialize the DataContext first!")

        const index = findProblemIndex(id)
        const object = { id, dependsOn: parent, ...data }

        if (index > -1)
            problems[index] = {
                ...problems[index],
                ...object
            }
        else
            problems.push(object)
    }

    const removeProblem = (id) => {
        if (!initialized)
            throw new Error("You need to initialize the DataContext first!")

        removeDependencies(id)

        const index = findProblemIndex(id)
        const found = index > -1

        if (found)
            problems.splice(index, 1)

        return found
    }


    /** Comorbidities functions */

    const addComorbidity = (comorbidity) => {
        if (!initialized)
            throw new Error("You need to initialize the DataContext first!")

        comorbidities.push(comorbidity)
    }

    const removeComorbidity = (comorbidity) => {
        if (!initialized)
            throw new Error("You need to initialize the DataContext first!")

        const index = comorbidities.indexOf(comorbidity)
        const found = index > -1
        
        if (found) {
            removeDependencies(comorbidity)
            comorbidities.splice(index, 1)
        }

        return found
    }


    /** Dependencies functions */

    // remove all dependencies of given id

    const removeDependencies = (id) => {
        if (!initialized)
            throw new Error("You need to initialize the DataContext first!")

        getLocalDependencies(id)
            .forEach(id => removeProblem(id))
    }

    // recursive search of local dependencies

    const getLocalDependencies = (id) => {
        if (!initialized)
            throw new Error("You need to initialize the DataContext first!")

        let dependencies = []
        
        problems.forEach(problem => {
            if (problem.dependsOn === id)
                dependencies = [
                    ...dependencies,
                    problem.id,
                    ...getLocalDependencies(problem.id)
                ]
        })

        return dependencies
    }


    /** Generate dataset to submit it */

    const generateDataset = () => {
        if (!initialized)
            throw new Error("You need to initialize the DataContext first!")

        else if (!canSubmit)
            throw new Error("You cannot generate a dataset while you can't submit")

        return {
            problems: problems
                .filter(problem => problem.hasProblem)
                .map(problem => {
                    delete problem.dependsOn
                    delete problem.hasProblem
                    return problem
                }),
            comorbidities: comorbidities
        }
    }


    /** Can submit functions */

    const canSubmit = () => {
        if (!initialized)
            throw new Error("You need to initialize the DataContext first!")

        return canSubmitProblems() && canSubmitComorbidities()
    }

    const canSubmitComorbidities = () => {
        // we get the comorbidities with dependent subquestions
        const index = mandatoryDependencies.findIndex(mandatoryDependency => mandatoryDependency.id === 'comorbidities')
        const selectedComorbiditiesWithMandatoryDependencies = mandatoryDependencies[index].dependencies
            .filter(x => x.dependencies.length > 0)
            .filter(x => comorbidities.includes(x.id))

        if (selectedComorbiditiesWithMandatoryDependencies.length === 0)
            return true

        // we ensure that each comorbidities selection dependency has been answered, 
        const foundDependencies = selectedComorbiditiesWithMandatoryDependencies
            .map(dependency => problems.findIndex(problem => problem.dependsOn === dependency.id) > -1)
        
        if (foundDependencies.length === 0)
            return false
        else
            return foundDependencies.reduce((previousFound, currentFound) => previousFound && currentFound)
    }

    const canSubmitProblems = () => {

        const verifyProblems = (pbs) => {
            return pbs
                .map(pb => {
                    let result = null

                    if (pb.id === 'comorbidities')
                        result = true
                    else
                        result = verifyProblem(pb)
                    
                    return result
                })
                .reduce((previous, current) => previous && current)
        }

        const verifyProblem = (pb) => {
            const index = problems.findIndex(x => x.id === pb.id)
            const found = index > -1

            if (!found)
                return false

            const childCheck = (pb.childs.length === 0) || verifyProblems(pb.childs)

            let dependencyCheck = true

            if (problems[index].hasProblem && pb.dependencies.length > 0)
                dependencyCheck = verifyProblems(pb.dependencies)

            return childCheck && dependencyCheck
        }

        return verifyProblems(mandatoryDependencies)
    }


    return {
        initialize,
        addProblem,
        removeProblem,
        removeDependencies,
        addComorbidity,
        removeComorbidity,
        generateDataset,
        canSubmit
    }
}

const DataContext = createContext(makeDataStore())

export default DataContext