import { useEffect, useState } from "react";
import { Button, OverlayTrigger, Tooltip, useAccordionButton } from "react-bootstrap"
import { useMediaQuery } from 'react-responsive'

const NavigationButton = ({ children, eventKey, type, callback, disabled=false }) => {
    const decoratedOnClick = useAccordionButton(
        eventKey,
        () => callback && callback(eventKey)
    )

    const isMobile = useMediaQuery({ query: `(max-width: 992px)` });
    const [btnWidth, setBtnWidth] = useState(isMobile ? 'w-100' : '')
    const [size, setSize] = useState(isMobile ? 'md' : 'lg')
    
    useEffect(() => {
        setBtnWidth(isMobile ? 'w-100' : '')
        setSize(isMobile ? 'md' : 'lg')
    }, [isMobile])

    let color, float

    switch (type) {
        case 'prev':
            color = 'danger'
            float = 'float-start'
            break

        case 'post':
        case 'next':
            color = 'green'
            float = 'float-end'
            break

        default:
            throw new Error("NavigationButton type not implemented")
    }

    if (type === 'post' && disabled)
        return (
            <OverlayTrigger
                placement={'top'}
                overlay={
                    <Tooltip id={`tooltip-send`}>
                        Vous n'avez pas répondu à toutes les questions
                    </Tooltip>
                }
            >
                <span className={`${float} ${btnWidth}`}>
                    <Button
                        variant={color}
                        size={size}
                        disabled={true}
                        className={'w-100'}>
                        {children}
                    </Button>
                </span>
            </OverlayTrigger>
        )

    else
        return (
            <Button
                variant={color}
                size={size}
                className={`${float} ${btnWidth}`}
                onClick={decoratedOnClick}
            >
                {children}
            </Button>
        )
}

export default NavigationButton