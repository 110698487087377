import { Col, Row, Button } from "react-bootstrap"
import { useContext, useState } from "react"
import PropTypes from 'prop-types'
import '@Styles/theme.css'
import SubmittableContext from "@Contexts/SubmittableContext"

const ResponseButtons = props => {
    const [yesButtonSelectionState, setValidButtonSelectionState] = useState(false)
    const [noButtonSelectionState, setCrossButtonSelectionState] = useState(false)

    const { updateSubmittable } = useContext(SubmittableContext)

    const xorReverse = (hasProblem) => {
        return (props.reverse && !hasProblem) || (!props.reverse && hasProblem)
    }

    const handleClick = hasProblem => {
        hasProblem = xorReverse(hasProblem)

        if (props.reverse) {
            setValidButtonSelectionState(hasProblem)
            setCrossButtonSelectionState(!hasProblem)
        
        } else {
            setValidButtonSelectionState(!hasProblem)
            setCrossButtonSelectionState(hasProblem)
        }

        props.triggerDependenciesHandler(hasProblem)
        updateSubmittable()
    }

    return (
        <>
            <Row>
                <Col xs={{span: 5, offset: 1}} lg={{span: 3, offset: 3}}>
                    <Button variant={"outline-green"}
                        className={'w-100'}
                        style={{borderRadius: '40px'}}
                        size={"lg"}
                        active={yesButtonSelectionState}
                        onClick={() => handleClick(false)}>
                        Oui
                    </Button>
                </Col>

                <Col xs={5} lg={3}>
                    <Button variant={"outline-danger"}
                        className={'w-100'}
                        style={{borderRadius: '40px'}}
                        size={"lg"}
                        active={noButtonSelectionState}
                        onClick={() => handleClick(true)}>
                        Non
                    </Button>
                </Col>
            </Row>
        </>
    )
}

ResponseButtons.propTypes = {
    triggerDependenciesHandler: PropTypes.func
}

export default ResponseButtons