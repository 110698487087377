import { Accordion, Card, Container, Stack } from "react-bootstrap"
import AnalyticsLogger from "../../AnalyticsLogger"
import '@Styles/theme.css'

const About = () => {
    AnalyticsLogger.logScreen('about')

    return (
        <Container fluid={'lg'} className="mb-5" style={{paddingTop: '2em'}}>

            <Stack gap={3}>
                <Stack direction={'horizontal'}>
                    <h2 className={"mb-4"}>À propos d'</h2>

                    <img src="logo-color.svg"
                        height="40"
                        alt="Apaclic colored logo"
                    />
                </Stack>
                
                <Stack gap={5}>
                    <Card className={'border-green'}>
                        <Accordion defaultActiveKey={'context'}>
                            <Accordion.Item eventKey={'context'}>
                                <Accordion.Header color={'#008515'}>Contexte</Accordion.Header>
                                <Accordion.Body>
                                    <p>
                                        L’activité physique adaptée (APA) est une activité physique (quotidien, loisir, sport) basée sur les aptitudes et les motivations des personnes à besoins spécifiques empêchant la pratique en milieu ordinaire.
                                        Objectif de l'APA : réduire les facteurs de risque et les limitations fonctionnelles liés aux pathologies chroniques.
                                    </p>

                                    <p>
                                        Le Décret n° 2016-1990 du 30 décembre 2016<sup>1</sup> concernant le sport sur ordonnance, place le médecin traitant au cœur du dispositif puisqu'il doit prescrire l'APA pour les patients en ALD.
                                        Les Professionnels de l’APA sont les kinésithérapeutes, ergothérapeutes, psychomotricien, enseignants en APA, éducateurs sportifs..., vers lesquels les patients sont orientés en fonction du nombre et de la sévérité de leurs limitations fonctionnelles.
                                    </p>

                                    <p>
                                        Les freins à la prescription d'APA sont identifiés dans la littérature<sup>2</sup> :
                                    </p>
                                    <ul>
                                        <li>Manque de formation des médecins</li>
                                        <li>Manque de temps en consultation</li>
                                        <li>Manque de remboursement de l'APA</li>
                                        <li>Difficultés d’orientation du patient</li>
                                        <li>Motivation du patient nécessaire</li>
                                    </ul>
                                    
                                    <p>
                                        Ce projet s'inscrit dans les politiques actuelles nationales et régionales (Stratégie Nationale Sport Santé 2019-2024, Second plan régional SSBE 2018-2022 Bretagne…).
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="what">
                                <Accordion.Header>Qu'est-ce que c'est ?</Accordion.Header>
                                <Accordion.Body>
                                    <p>
                                        Apaclic est un outil informatique d’aide à la prescription APA, destiné en première intention aux médecins généralistes, mais pouvant être utilisé par tous les médecins souhaitant réaliser une telle prescription pour un patient.
                                        Cet outil tente de répondre aux principaux freins de prescription d’APA identifiés dans la littérature.
                                        Il a été construit à partir du tableau des phénotypes fonctionnels.
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="who">
                                <Accordion.Header>Par qui ?</Accordion.Header>
                                <Accordion.Body>
                                    <p>
                                        Cet outil est le fruit d’un travail de thèses de médecine générale réalisées au sein du Département de Médecine Générale de Rennes et soutenues en avril 2021 par Dr Friteau Julien<sup>3</sup> et Dr Tanchoux Tristan<sup>4</sup> lors de leur internat, thèses encadrées par le Dr Vincent Daniel exerçant au sein du service de Médecine du sport au CHU de Rennes.
                                        Cet outil a également été élaboré sur avis d'enseignants du Département de Médecine Générale de Rennes et de l'Université STAPS APA Santé de Rennes.
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>

                            <Accordion.Item eventKey="how">
                                <Accordion.Header>Comment ?</Accordion.Header>
                                <Accordion.Body>
                                    <p>
                                        Cet outil a été développé par Alexis Choupault, élève ingénieur à l’ENSSAT de Lannion.
                                    </p>
                                </Accordion.Body>
                            </Accordion.Item>
                                
                            <Accordion.Item eventKey="references">
                                <Accordion.Header>Références bibliographiques</Accordion.Header>
                                    <Accordion.Body>
                                        <p>
                                            <sup>1</sup> Décret n°2016-1990 du 30 décembre 2016 relatif aux conditions de dispensation de l’activité physique adaptée prescrite par le médecin traitant, version 18 octobre 2020, (Articles D1172-1 à D1172-5) 
                                            <br /><br />
                                            <sup>2</sup> B. Rucar, Sport santé sur ordonnance : analyse des ressentis et des freins concernant la prescription d'activités physiques adaptées pour les patients en ALD chez des médecins généralistes ayant réalisés une formation à cette prescription, thèse d’exercice pour diplôme d’état de Docteur en médecine, Université de Lille, 2018 
                                            <br /><br />
                                            <sup>3</sup> Friteau Julien, Élaboration d'un outil informatisé d'aide à la prescription d'Activité physique adaptée en médecine générale. Recueil du ressenti et expérimentation de l’outil par des enseignants en APA de l’Ouest, thèse d’exercice pour diplôme d’état de Docteur en médecine, université de Rennes, 2021.
                                            <br /><br />
                                            <sup>4</sup> Tanchoux Tristan, Élaboration d’un outil informatisé d’aide à la prescription d’activité physique adaptée, et expérimentation de cet outil par des médecins généralistes en Bretagne pour une utilisation en pratique courante, thèse d’exercice pour diplôme d’état de Docteur en médecine, Université de Rennes, 2021. 
                                        </p>
                                </Accordion.Body>
                            </Accordion.Item>
                        </Accordion>
                    </Card>
                </Stack>
            </Stack>
        </Container>
    )
}

export default About