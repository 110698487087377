import 'bootstrap/dist/css/bootstrap.min.css'
import Navbar from "./presentational/navbar/Navbar"
import { BrowserRouter, Route, Routes } from 'react-router-dom'
import Home from '../pages/Home/'
import Form from '../pages/Form/'
import About from '../pages/About/'
import Feedback from '../pages/Feedback'
import Refund from '../pages/Refund'
import { default as R } from '../Route'

const App = () => (
    <BrowserRouter>
        <Navbar />

        <Routes>
            <Route path={R.Home} element={<Home />}/>
        </Routes>

        <Routes>
            <Route path={R.Form} element={<Form />}/>
        </Routes>

        <Routes>
            <Route path={R.About} element={<About />}/>
        </Routes>

        <Routes>
            <Route path={R.Feedback} element={<Feedback />} />
        </Routes>

        <Routes>
            <Route path={R.Refund} element={<Refund />} />
        </Routes>
    </BrowserRouter>
)

export default App