import { Nav } from 'react-bootstrap'

const NavLink = ({href, children}) => {
    const isActive = href === window.location.pathname

    return (
        <Nav.Link active={isActive} href={href}>
            {children}
        </Nav.Link>
    )
}

export default NavLink