import { Card, Col, Row, Stack } from 'react-bootstrap'
import { createRef, useContext, useState } from 'react'
import SimpleQuestion from './SimpleQuestion'
import PropTypes from 'prop-types'
import SelectionButton from '@Components/presentational/selectionButton/SelectionButton'
import CollapseWidget from '@Components/container/collapseWidget/CollapseWidget'
import DataContext from '@Contexts/DataContext'

const ComorbiditiesQuestion = props => {
    const [selectedComorbidities, setSelectedComorbidities] = useState([])
    const { addComorbidity, removeComorbidity } = useContext(DataContext)
    
    const choicesWithDependencies = props.dependencies.filter(dependency => dependency.dependencies.length > 0)
    const collapseRefs = choicesWithDependencies.map(choice => ({
        id: choice.id,
        ref: createRef()
    }))

    const changeSelectedComorbidities = comorbidity => {
        let found = false

        setSelectedComorbidities(prevState => {
            const index = selectedComorbidities.indexOf(comorbidity)
            found = index > -1

            if (found)
                prevState.splice(index, 1)
            else
                prevState.push(comorbidity)

            return prevState
        })

        if (found)
            removeComorbidity(comorbidity)
        else
            addComorbidity(comorbidity)
    }

    const handleChange = id => {
        changeSelectedComorbidities(id)

        const foundObject = collapseRefs.find(collapseRef => collapseRef.id === id)

        if (foundObject)
            foundObject.ref.current.setOpen()
    }

    return (
        <Card.Body>
            <Stack gap={2}>
                <Card.Text>{props.question}</Card.Text>

                    <Row xs={1} lg={3}>
                        {props.dependencies.map((choice, index) => (
                            <Col className={'mt-1'} key={index}>
                                <SelectionButton
                                    key={index}
                                    id={choice.id}
                                    title={choice.title}
                                    handleChange={() => handleChange(choice.id)}
                                />
                            </Col>
                        ))}
                    </Row>

                    <Stack gap={4}>
                        {choicesWithDependencies.map((choice, index) => (
                            <CollapseWidget key={index} ref={collapseRefs[index].ref}>
                                {choice.dependencies.map((question, index) => (
                                    <SimpleQuestion key={index}
                                        id={question.id}
                                        title={question.title}
                                        parent={choice.id}
                                        question={question.question}
                                        reverse={question.reverse}
                                    />
                                ))}
                            </CollapseWidget>
                        ))}
                    </Stack>
            </Stack>
        </Card.Body>
    )
}

ComorbiditiesQuestion.propTypes = {
    id: PropTypes.string,
    question: PropTypes.string,
    dependencies: PropTypes.arrayOf(PropTypes.shape({
        id: PropTypes.string,
        title: PropTypes.string,
        dependencies: PropTypes.array
    }))
}

export default ComorbiditiesQuestion