import { Container } from "react-bootstrap"

const Refund = () => (
    <Container fluid={'lg'}>
         <p>
            Nous recherchons des financements afin de développer Apaclic et proposer de nouvelles fonctionnalités.
        </p>
    </Container>
)

export default Refund